import { useCallback, useEffect, useRef } from 'react';
export const canUseDOM = typeof window !== 'undefined';
export function dethunkify(value) {
    return typeof value === 'function' ? value() : value;
}
export function managedEventListener(target, type, callback, options) {
    target.addEventListener(type, callback, options);
    return () => {
        target.removeEventListener(type, callback, options);
    };
}
export function managedInterval(callback, delayMs) {
    const id = setInterval(callback, delayMs);
    return () => {
        clearInterval(id);
    };
}
export function useEventCallback(callback) {
    // Source: https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
    const ref = useRef();
    useEffect(() => {
        ref.current = callback;
    }, [callback]);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useCallback((...args) => ref.current(...args), [ref]);
}
